import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import './css/Main.scss';
import namelogo from './namelogo.png';
import { Route, Switch, useLocation } from 'react-router-dom';
import LoginSplash from './LoginSplash/loginsplash';
import IdleTracker from './Components/Utilities/IdleTracker';
import { QueryClient, QueryClientProvider } from 'react-query';

import Dash from './pages/Dash';

// client mgmt
import ClientList from './pages/Admin/Clients/ClientList';
import AddClientUser from './pages/Admin/Clients/AddEditClientModal';

// user assessment views
import AssignedAssessments from './pages/AssignedAssessments';

// user info
import Profile from './pages/Profile.js';
import Demographics from './pages/Demographics.js';

import TakeAssessment from './pages/TakeAssessment';
import ReportDisplay from './pages/ReportDisplay';

// candidate views
import CandidateResults from './pages/Candidate/Results';
import PendingRequests from './pages/Candidate/PendingRequests';

// firm views
import ClientSearch from './pages/Client/ClientSearch';
import ClientResult from './pages/Client/ClientResult';
import ClientInvite from './pages/Client/ClientInvite';

// svg icon imports
import Navigation from './Components/Navigation';
import BasicSnackbar from './Components/Utilities/BasicSnackbar';

import Loader from './Components/Loader';
import { getBaseUrl } from './config';

import ClientSettings from './pages/Client/ClientSettings/ClientSettings';

import ClientSupport from './pages/Client/ClientSupport/UpdateTicket';
import CreateTicket from './pages/Client/ClientSupport/CreateTicket/CreateTicket';
import Survey from './pages/Client/Survey/Survey';

// admin: survey data
import SurveyData from './pages/Admin/SurveyData';

// admin: client management panel
import ClientManage from './pages/Admin/Clients/ClientManage';
import ClientSubscriptions from './pages/Admin/Clients/ClientSubscriptions';
import JobOverview from './pages/Admin/Jobs/JobOverview';
import FilterSelector from './pages/Admin/FilterSelector';
import UsersList from './pages/Users/UsersList';
import UserCreate from './pages/Users/UserCreate';
import Assessments from './pages/Admin/Assessments/Assessments.js';
import { AdminDebugRoutes } from './pages/Admin/Debug/AdminDebugRoutes';

// error pages
import Error404 from './pages/Errors/404';
import Error403 from './pages/Errors/403';

// Temporary import for staging preview
import Developmental from './Reports/DevelopmentalFullReport';

import NormVersions from './pages/Admin/Norms/NormVersions.js';
import CreateNorm from './pages/Admin/Norms/CreateNorm.js';
import { Box } from '@mui/system';
import { closeSnackbar } from './redux/actions/snackbar.js';

const BASE_URL = getBaseUrl();

const queryClient = new QueryClient();

const RoutesWrapper = ({ user, fetchProfile, loggedIn }) => (
  <QueryClientProvider client={queryClient}>
    <Switch>
      <Route exact path="/" render={() => <Dash user={user} />} />

      {/* <Route exact /> */}
      <Route
        exact
        path="/assessments/assigned"
        component={AssignedAssessments}
      />
      <Route
        exact
        path="/profile"
        render={() => <Profile user={user} setProfile={fetchProfile} />}
      />
      <Route exact path="/demographics" component={Demographics} />
      <Route exact path="/take" component={TakeAssessment} />

      {loggedIn ? (
        <Route exact path="/code/:id" component={Dash} />
      ) : (
        <Route exact path="/code/:id" component={LoginSplash} />
      )}

      {/* candidate routes*/}
      <Route exact path="/candidate/results" component={CandidateResults} />
      <Route exact path="/pending-requests" component={PendingRequests} />

      {/* firm routes */}
      <Route exact path="/firm/report/:id" component={ReportDisplay} />
      <Route exact path="/firm/dash" component={Dash} />
      <Route
        exact
        path="/firm/jobs/:jobId/candidates"
        component={ClientSearch}
      />
      <Route exact path="/firm/search/result" component={ClientResult} />
      <Route exact path="/firm/invite" component={ClientInvite} />
      <Route exact path="/firm/settings" component={ClientSettings} />
      <Route exact path="/users" component={UsersList} />
      <Route exact path="/users/create" component={UserCreate} />
      <Route exact path="/support" component={ClientSupport} />
      <Route exact path="/support/create" component={CreateTicket} />
      <Route exact path="/survey/:id" component={Survey} />

      {/* Admin routes */}
      <Route exact path="/admin/clients" component={ClientList} />
      <Route exact path="/admin/clients/delegate" component={AddClientUser} />
      <Route exact path="/admin/client/manage" component={ClientManage} />
      <Route
        exact
        path="/admin/client/:clientId/subscription"
        component={ClientSubscriptions}
      />
      <Route exact path="/admin/job/:jobId/overview" component={JobOverview} />
      <Route exact path="/admin/surveydata" component={SurveyData} />
      <Route exact path="/admin/create/entrylevel" component={FilterSelector} />
      <Route
        exact
        path="/admin/norms"
        render={() => <NormVersions user={user} />}
      />
      <Route
        exact
        path="/admin/norms/create"
        render={() => <CreateNorm user={user} />}
      />
      <Route exact path="/admin/assessments" component={Assessments} />
      <AdminDebugRoutes />

      {/* Temporary route for staging preview  */}
      <Route exact path="/test/devreport" component={Developmental} />

      {/*  Error pages */}
      <Route exact path="/unauthorized" component={Error403} />
      <Route exact path="/notfound" component={Error404} />
      <Route component={Error404} />
    </Switch>
  </QueryClientProvider>
);

const App = ({ snackbar, closeSnackbar }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [user, setUser] = useState({ perms: [] });
  const [lastActivity, setLastActivity] = useState(0);

  const location = useLocation();
  const isSurvey = /^\/survey\/.+/.test(location.pathname);

  useEffect(() => {
    fetchProfile(true);
  }, [isSurvey]);

  const fetchProfile = onLogin => {
    fetch('/api/users/session', {
      headers: {
        accepts: 'application/json',
      },
      method: 'get',
    })
      .then(res => res.json())
      .then(res => {
        if (onLogin) {
          setIsLoading(false);
          setLoggedIn(_.get(res, 'user_id', null) ? true : false);
        }
        setUser(value => ({ ...value, ...res }));
      })
      .then(() => {})
      .catch(err => {
        if (onLogin) {
          if (!isSurvey) {
            console.log(`Error fetching profile: ${err}`);
            window.location = `${BASE_URL}/api/login?returnTo=${window.location.pathname}`;
            return;
          }
          setIsLoading(false);
        }
      });
  };

  if (isLoading) {
    return <Loader minHeight={'100vh'} />;
  }

  if (isSurvey) {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 2,
            pb: 2,
            pr: 2,
            pl: 2,
          }}
        >
          <img
            style={{
              width: 203,
              height: 65,
            }}
            alt="Thine"
            src={namelogo}
          />
        </Box>
        <RoutesWrapper
          user={null}
          fetchProfile={fetchProfile}
          loggedIn={loggedIn}
        />
      </Box>
    );
  }

  if (loggedIn === true) {
    return (
      <div className="screen">
        <div className="sr-only no-print">
          <a href="#maincontent" name="Main content">
            Skip to main content
          </a>
        </div>
        <div className="mobile-header no-print">
          <header>
            <img alt="Thine" src={namelogo} />
          </header>
        </div>
        {user.perms.length > 0 ? (
          <Navigation user={user} baseUrl={BASE_URL}></Navigation>
        ) : null}
        <div className="page">
          <main id="maincontent">
            <IdleTracker baseUrl={BASE_URL}>
              <RoutesWrapper
                user={user}
                fetchProfile={fetchProfile}
                loggedIn={loggedIn}
              />
              <BasicSnackbar
                message={snackbar.message}
                snackbarOpen={snackbar.open}
                setSnackbarOpen={closeSnackbar}
                severity={snackbar.severity}
              />
            </IdleTracker>
          </main>
        </div>
      </div>
    );
  } else {
    return (
      <div className="screen">
        <LoginSplash />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    snackbar: state.snackbar,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeSnackbar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
