import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SendIcon from '@mui/icons-material/Send';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GavelIcon from '@mui/icons-material/Gavel';
import ArticleIcon from '@mui/icons-material/Article';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../wlogo.svg';
import { Button, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import namelogo from '../namelogo.png';
import BackgroundLetterAvatar from './Avatar';

const drawerWidth = 240;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const links = {
  recruiter: {
    links: [
      { text: 'Home', icon: <HomeIcon />, href: '/' },
      // { text: "Office Location", icon: <ApartmentIcon />, href: "/location" },
      { text: 'Users', icon: <PersonIcon />, href: '/users' },
      { text: 'Settings', icon: <SettingsIcon />, href: '/firm/settings' },
      {
        text: 'Support',
        icon: <SupportAgentIcon />,
        href: '/support',
      },
      { text: 'Invite', icon: <SendIcon />, href: '/firm/invite' },
    ],
    isPermitted: perms => {
      return perms.includes(5);
    },
  },
  candidate: {
    links: [
      { text: 'Home', icon: <HomeIcon />, href: '/' },
      { text: 'Profile', icon: <PersonIcon />, href: '/profile' },
      {
        text: 'My Results',
        icon: <CheckCircleIcon />,
        href: '/candidate/results',
      },
      { text: 'Approvals', icon: <HomeIcon />, href: '/pending-requests' },
    ],
    isPermitted: perms => {
      return perms.includes(3);
    },
  },
  admin: {
    links: [
      { text: 'Home', icon: <HomeIcon />, href: '/' },
      { text: 'Profile', icon: <PersonIcon />, href: '/profile' },
      { text: 'Support', icon: <SupportAgentIcon />, href: '/support' },
      { text: 'Norms', icon: <GavelIcon />, href: '/admin/norms' },
      {
        text: 'Assessments',
        icon: <AssessmentIcon />,
        href: '/admin/assessments',
      },
      // { text: "Clients", icon: <PersonIcon />, href: "/admin/clients" },
    ],
    isPermitted: perms => {
      return perms.includes(4);
    },
  },
  surveyLinks: {
    links: [
      {
        text: 'Trait Selection Survey',
        icon: <ArticleIcon />,
        href: '/traits',
      },
    ],
    isPermitted: perms => {
      return perms.includes(8);
    },
  },
};

const generateDawerLinks = (links, pathname, perms) => {
  let drawerLinks = Object.keys(links).map((key, index) => {
    if (links[key].isPermitted(perms)) {
      return links[key].links.map((link, index) => (
        <Link to={link.href} key={index} style={{ textDecoration: 'none' }}>
          <ListItem
            className={pathname === link.href ? 'current-page' : ''}
            button
            sx={{
              color: '#25282b',
              '&.current-page': {
                backgroundColor: '#00629b',
                color: '#fff',
              },
            }}
          >
            <ListItemIcon sx={{ color: 'inherit' }}>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItem>
        </Link>
      ));
    }

    return [];
  });

  return drawerLinks.flat();
};

const AppBarLinks = ({ links, pathname, perms }) => {
  const history = useHistory();
  const permittedLinkType = Object.keys(links).find(key => {
    return links[key].isPermitted(perms);
  });

  const permittedLinks = links[permittedLinkType].links;
  return (
    <>
      {permittedLinks.map((link, index) => (
        <Button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            history.push(link.href);
          }}
          key={index}
          className={pathname === link.href ? 'current-page' : ''}
          sx={{
            my: 2,
            textTransform: 'none',
            textDecoration: 'none',
            color: 'white',
            fontWeight: 'bold',
            fontFamily: "'Roboto', sans-serif",
            display: 'block',
            '&.current-page': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
            },
            whiteSpace: 'nowrap',
            minWidth: 'max-content',
          }}
        >
          {link.text}
        </Button>
      ))}
    </>
  );
};

function Navigation(props) {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const { window, user, baseUrl } = props;
  const perms = user.perms;
  const impersonated_by = user.impersonated_by;
  const username = `${user.first_name} ${user.last_name}`;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Link to="/">
        <img
          style={{ height: 65, width: 204 }}
          className="navbarLogo"
          src={namelogo}
          alt="Thine Logo"
        />
      </Link>
      <Divider />
      <List>{generateDawerLinks(links, location.pathname, perms)}</List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} className={'no-print'}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
          ml: { ModalProps: `${drawerWidth}px` },
          backgroundColor: impersonated_by ? 'red' : '#00629b',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              marginTop: 1,
              flexGrow: lg ? 0 : 1,
            }}
          >
            <Link to="/">
              <Logo
                style={{ height: 65, width: 168 }}
                className="navbarLogo"
                fill="#000"
                stroke="#000"
              />
            </Link>
          </Typography>
          <Box
            sx={{
              flexGrow: lg ? 1 : 0,
              display: lg ? 'flex' : 'none',
              flexDirection: 'row',
              marginLeft: 5,
            }}
          >
            <AppBarLinks
              links={links}
              pathname={location.pathname}
              perms={perms}
            />
          </Box>
          <Stack spacing={2} direction="row">
            <div className="mobile-hide">
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
            </div>

            <BackgroundLetterAvatar
              baseUrl={baseUrl}
              name={username}
              impersonated_by={impersonated_by}
            />
          </Stack>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
